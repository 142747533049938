import { Component } from '@angular/core';
import { Platform, AlertController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router, NavigationEnd } from '@angular/router';
import { AppRate } from '@ionic-native/app-rate/ngx';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { Storage } from '@ionic/storage';
import { AngularFirestore } from '@angular/fire/firestore';

declare let gtag: Function;
declare var StartAppAds: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})

export class AppComponent {

  constructor(
    public router: Router,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private appRate: AppRate,
    private firebaseX: FirebaseX,
    private storage: Storage,
    private afs: AngularFirestore,
    public alertController: AlertController
  ) {
    this.initializeApp();
  }

  async initializeApp() {
    await this.platform.ready().then(() => {
      this.storage.get('user').then(user => {
        if (user) {
          this.afs.collection('users').doc(user.key).ref.get().then(user => {
            var data = user.data();
            if (data) {
              this.storage.set('user', data);
            }
            if (this.platform.is('cordova')) {
              StartAppAds.init("200034321");
              this.statusBar.styleDefault();
              this.splashScreen.hide();
              this.appRating();
              this.firebaseX.getToken()
                .then(token => this.afs.collection('users').doc(data.key).update({ token: token }).then(() => {
                  this.afs.collection('devices').doc(token).set({ token: token, uid: data.key });
                }))
                .catch(error => console.error('Error getting token', error));

              this.firebaseX.onMessageReceived()
                .subscribe(dataNoti => {
                  console.log('this is the message');
                  console.log(dataNoti);
                  if (dataNoti.title == 'Payment due') {
                    this.presentAlertNoti(dataNoti)
                  }
                  else if (dataNoti.title == 'Account suspended') {
                    this.presentAlertNotiOverdue();
                  }
                  else {
                    this.router.navigate(['/news'])
                  }
                });

              this.firebaseX.onTokenRefresh()
                .subscribe((token: string) => this.afs.collection('users').doc(data.key).update({ token: token }).then(() => {
                  this.afs.collection('devices').doc(token).set({ token: token, uid: data.key });
                }));
            } else {
              this.router.events.subscribe(event => {
                if (event instanceof NavigationEnd) {
                  gtag('config', 'UA-154782873-1',
                    {
                      'page_path': event.urlAfterRedirects
                    });
                }
              });
            }
          });
        }
      });
    });
  }
  
  async presentAlertNoti(message) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: message.title,
      message: message.body,
      buttons: [
        {
          text: 'Pay Later',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel');
          }
        },
        {
          text: 'Pay Now',
          cssClass: 'secondary',
          handler: () => {
            this.router.navigate(['sales-stats/notify']);
          }
        }
      ]
    });

    await alert.present();
  }

  async presentAlertNotiOverdue() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Account deactivated',
      message: 'You account has been deactivated due to non-payment. To continue pay your account',
      buttons: [
        {
          text: 'Pay Later',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel');
            this.router.navigate(['home'])

          }
        },
        {
          text: 'Pay Now',
          cssClass: 'secondary',
          handler: () => {
            this.router.navigate(['sales-stats/notify']);
          }
        }
      ]
    });

    await alert.present();
  }
  appRating() {
    this.appRate.preferences = {
      displayAppName: 'Pasella',
      usesUntilPrompt: 3,
      promptAgainForEachNewVersion: false,
      inAppReview: true,
      storeAppURL: {
        android: 'market://details?id=com.entelek.pasella',
      },
      customLocale: {
        title: "Would you mind rating Pasella App?",
        message: "It won’t take more than a minute and helps to promote our app. Thanks for your support!",
        cancelButtonLabel: "No, Thanks",
        laterButtonLabel: "Later",
        rateButtonLabel: "Rate It Now",
        yesButtonLabel: "Yes!",
        noButtonLabel: "Not Really",
        appRatePromptTitle: 'Do you like using Pasella App',
        feedbackPromptTitle: 'Mind giving us some feedback?',
      },
      callbacks: {
        handleNegativeFeedback: function () {
          window.open('mailto:brad@entelek.co.za', '_system');
        },
        onRateDialogShow: function (callback) {
          callback(1) // cause immediate click on 'Rate Now' button
        },
        onButtonClicked: function (buttonIndex) {
          console.log("onButtonClicked -> " + buttonIndex);
        }
      },
    };
    this.appRate.promptForRating(false)
  }

}
