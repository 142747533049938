import { Component, Input } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute } from '@angular/router';
import { AlertController, ModalController, PopoverController } from '@ionic/angular';
import { PopupHelper } from 'src/app/services/helpers/popup-helper';

@Component({
  selector: 'app-device-settings',
  templateUrl: './device-settings.page.html',
  styleUrls: ['./device-settings.page.scss'],
})
export class DeviceSettings {

  vouchers = [];
  id;
  values = [];
  keys = [];
  @Input() device; any;
  options = '';
  newCommission: number;
  constructor(
    private afs: AngularFirestore,
    private modalController: ModalController,
    public alertCtrl: AlertController,
    private popUp: PopupHelper
  ) {
  }

  ionViewDidEnter() {
    console.log('this is the device');
    console.log(this.device);
    this.values = Object.values(this.device);
    this.keys = Object.keys(this.device);
    this.values[0] = [];
    this.values[1] = []
    if(this.device.managerCommission)  {
      this.newCommission  = this.device.managerCommission;
    }
    console.log(this.values);
  }

  getType(object) {
    return typeof object
  }

  exit() {
    this.modalController.dismiss();
  }

  close() {
    this.modalController.dismiss(this.newCommission);
  }
  
  async surityCheck() {
    let prompt = await this.alertCtrl.create({
      header: 'Are you sure you want to save current details',
      cssClass: 'alert',
      message: `Previous settings will be overwritten`,
      buttons: [
        {
          text: 'No',
          role: 'cancel'
        },
        {
          text: 'yes',
          handler: () => {
            this.save();
          }
        }
      ],

    });
    return await prompt.present();
  }
  save() {

    if (!this.newCommission) {
      return this.popUp.showError('commission cannot be null')
    }
    this.popUp.showLoading('saving settings').then(() => {
      this.afs.collection('operators').doc(this.device.key).ref.update({
        "managerCommission": this.newCommission
      }).then(() => {
        this.popUp.dismissLoading().then(() => {
          this.popUp.showToast('Saved').then(() => {
            this.exitCheck();
          })
        })
      })
    })
  }
  async exitCheck() {
    let prompt = await this.alertCtrl.create({
      header: 'Your settings have been saved, do you want to close settings?',
      cssClass: 'alert',
      buttons: [
        {
          text: 'No',
          role: 'cancel'
        },
        {
          text: 'yes',
          handler: () => {
            this.close();
          }
        }
      ],

    });
    return await prompt.present();
  }
}
