import { Injectable } from "@angular/core";

import {WebHttpClient} from 'ngx-http-client';
@Injectable()
export class SalesService {
    corsUrl = 'https://server.meetmyamigo.com:9998/';
    mainUrl = 'https://us-central1-mahala-app.cloudfunctions.net/GetSales';
    constructor(
        private http: WebHttpClient
    )   {

    }
    public getSales(domainId, gatewayId){
        const promise = new Promise((resolve, reject) => {
            console.log('getting sales.....');
            
            this.http.post(
                this.corsUrl + this.mainUrl,
                {
                    domainId: domainId,
                    gatewayId: gatewayId
                },
                {
                    headers:  {
                        'Content-Type': 'application/json',
                        "Accept-Charset": "utf-8"

                    }
                }
                ).subscribe((res: any) => {
                    console.log('this is teh res');
                    console.log(res);
                    resolve(res)
                })
        })
        return promise;
    }
}