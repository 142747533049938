export const FIREBASE_CONFIG = {
    apiKey: "AIzaSyA_Sc0-00O71lwSTCZNsS4QKkz7yp6ufa0",
    authDomain: "mahala-app.firebaseapp.com",
    databaseURL: "https://mahala-app.firebaseio.com",
    projectId: "mahala-app",
    storageBucket: "mahala-app.appspot.com",
    messagingSenderId: "596479374422",
    appId: "1:596479374422:web:e4c08101eabeac36d937f7",
    
    measurementId: "G-JDN4BCN84H"
};