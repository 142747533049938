import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { IonicModule } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { HTTP } from '@ionic-native/http/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { FIREBASE_CONFIG } from './app.firebase.config';
import { AngularFirestoreModule, FirestoreSettingsToken } from '@angular/fire/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireModule } from '@angular/fire';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { LoadingService } from './services/loading.service';
import { LaunchNavigator } from '@ionic-native/launch-navigator/ngx';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { IonicStorageModule } from '@ionic/storage';
import { ToastService } from './services/toast.service';
import { AppRate } from '@ionic-native/app-rate/ngx';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { FirebaseAuthentication } from '@ionic-native/firebase-authentication/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { File } from '@ionic-native/file/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import { PolicyPageModule } from './pages/policy/policy.module';
import { SalesService } from './pages/voucher-stats/sales.service';
import { HttpClientModule, WebHttpClient } from 'ngx-http-client';
import { FCM } from '@ionic-native/fcm/ngx';
import { PayNotifyService } from './services/pay-notify/pay-notify.service';
import { PopupHelper } from './services/helpers/popup-helper';
import { IonicSelectableModule } from 'ionic-selectable';
import { SubComponentsModule } from './pages/sub-components/sub-components.module';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { ClipBoardService } from './services/helpers/ClipBoard.service';
import { AngularFireFunctions } from '@angular/fire/functions';
import { WifiBundleService } from './services/wifi-bundles-service/wifi-bundles-service'
@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    AngularFireModule.initializeApp(FIREBASE_CONFIG),
    AngularFirestoreModule.enablePersistence(),
    AngularFireDatabaseModule,
    IonicStorageModule.forRoot(),
    AngularFireAuthModule,
    AngularFireStorageModule,
    HttpClientModule,
    PolicyPageModule,
    IonicSelectableModule,
    SubComponentsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    StatusBar,
    SplashScreen,
    Geolocation,
    LaunchNavigator,
    FirebaseAuthentication,
    FirebaseX,
    LoadingService,
    SocialSharing,
    InAppBrowser,
    AppRate,
    ToastService,
    File,
    FileOpener,
    Camera,
    HTTP,
    FCM,
    PayNotifyService,
    WebHttpClient,
    SalesService,
    PopupHelper,
    Clipboard,
    AngularFireFunctions,
    ClipBoardService,
    WifiBundleService,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: FirestoreSettingsToken, useValue: {} }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
