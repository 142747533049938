import { Injectable } from '@angular/core';
import { HTTP } from '@ionic-native/http/ngx';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AngularFireFunctions } from '@angular/fire/functions';

@Injectable({
    providedIn: 'root'
})
export class WifiBundleService {

    apiUrl = 'https://us-central1-mahala-app.cloudfunctions.net/purchaseBundle'

    corsUrl = 'https://server.meetmyamigo.com:9998/';
    smsApikey = '4UfyRHg9RZOfU8S9sdBXYw==';

    constructor(private http: HTTP, private httpClient: HttpClient, private functions: AngularFireFunctions) { }

    public purchaseBundle2(domainId: string, productId: string, sellPrice) {
        return new Promise((resolve, reject) => {
            const callable = this.functions.httpsCallable('purchaseBundle');
            const obs = callable(
                {
                    domainId,
                    productId,
                    sellPrice
                }
            );
            obs.subscribe(async res => {
                console.log('Resp: ', res);
                resolve(res);
            })
        })

    }

    public async sendSms(number: string, code: string) {
        const callable = this.functions.httpsCallable('sendSms');
        const obs = callable(
            {
                number,
                code,
            }
        );
        obs.subscribe(async res => {
            console.log('Resp: ', res);
        })

    }

    public sendNetcareSms(number: string, code: string) {
        var xhr = new XMLHttpRequest(),
            body = JSON.stringify(
                {
                    "messages": [
                        {
                            "channel": "sms",
                            "to": '+27' + number.substring(1),
                            "content": `Thank you for purchasing a NetcarePlus Voucher! Your voucher code is: ${code}. Please contact your selected doctor to make and appointment`
                        }
                    ]
                }
            );

        xhr.open('POST', this.corsUrl + 'https://platform.clickatell.com/v1/message', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.setRequestHeader('Authorization', this.smsApikey);
        xhr.onreadystatechange = () => {
            if (xhr.readyState == 4 && xhr.status == 200) {
                console.log('success');
            }
        };

        xhr.send(body);
    }
}
