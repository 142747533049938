import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '404', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule) },
  {
    path: 'view-article',
    loadChildren: () => import('./pages/view-article/view-article.module').then(m => m.ViewArticlePageModule)
  },
  {
    path: 'sport-sa',
    loadChildren: () => import('./pages/sport-sa/sport-sa.module').then(m => m.SportSaPageModule)
  },
  {
    path: 'entertainment',
    loadChildren: () => import('./pages/entertainment/entertainment.module').then(m => m.EntertainmentPageModule)
  },
  {
    path: 'money',
    loadChildren: () => import('./pages/money/money.module').then(m => m.MoneyPageModule)
  },
  {
    path: 'health-fitness',
    loadChildren: () => import('./pages/health-fitness/health-fitness.module').then(m => m.HealthFitnessPageModule)
  },
  {
    path: 'news',
    loadChildren: () => import('./pages/news/news.module').then(m => m.NewsPageModule)
  },
  {
    path: 'wifi-near-me',
    loadChildren: () => import('./pages/wifi-near-me/wifi-near-me.module').then(m => m.WifiNearMePageModule)
  },
  {
    path: 'view-wifi-location',
    loadChildren: () => import('./pages/view-wifi-location/view-wifi-location.module').then(m => m.ViewWifiLocationPageModule)
  },
  {
    path: 'request-hotspot',
    loadChildren: () => import('./pages/request-hotspot/request-hotspot.module').then(m => m.RequestHotspotPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterPageModule)
  },
  {
    path: 'privacy',
    loadChildren: () => import('./pages/privacy/privacy.module').then(m => m.PrivacyPageModule)
  },
  {
    path: 'terms',
    loadChildren: () => import('./pages/terms/terms.module').then(m => m.TermsPageModule)
  },
  {
    path: 'beyond-customer-services',
    loadChildren: () => import('./pages/beyond-customer-services/beyond-customer-services.module').then(m => m.BeyondCustomerServicesPageModule)
  },
  {
    path: 'study-material',
    loadChildren: () => import('./pages/customer-material/study-material.module').then(m => m.StudyMaterialPageModule)
  },
  {
    path: 'quiz1',
    loadChildren: () => import('./pages/customer-quiz1/quiz1.module').then(m => m.Quiz1PageModule)
  },
  {
    path: 'quiz2',
    loadChildren: () => import('./pages/customer-quiz2/quiz2.module').then(m => m.Quiz2PageModule)
  },
  {
    path: 'education',
    loadChildren: () => import('./pages/education/education.module').then(m => m.EducationPageModule)
  },
  {
    path: 'email-material',
    loadChildren: () => import('./pages/email-material/email-material.module').then(m => m.EmailMaterialPageModule)
  },
  {
    path: 'email-professionalism',
    loadChildren: () => import('./pages/email-professionalism/email-professionalism.module').then(m => m.EmailProfessionalismPageModule)
  },
  {
    path: 'email-quiz1',
    loadChildren: () => import('./pages/email-quiz1/email-quiz1.module').then(m => m.EmailQuiz1PageModule)
  },
  {
    path: 'email-quiz2',
    loadChildren: () => import('./pages/email-quiz2/email-quiz2.module').then(m => m.EmailQuiz2PageModule)
  },
  {
    path: 'email-quiz3',
    loadChildren: () => import('./pages/email-quiz3/email-quiz3.module').then(m => m.EmailQuiz3PageModule)
  },
  {
    path: 'email-quiz4',
    loadChildren: () => import('./pages/email-quiz4/email-quiz4.module').then(m => m.EmailQuiz4PageModule)
  },
  {
    path: 'customer-quiz3',
    loadChildren: () => import('./pages/customer-quiz3/customer-quiz3.module').then(m => m.CustomerQuiz3PageModule)
  },
  {
    path: 'customer-quiz4',
    loadChildren: () => import('./pages/customer-quiz4/customer-quiz4.module').then(m => m.CustomerQuiz4PageModule)
  },
  {
    path: 'fun',
    loadChildren: () => import('./pages/fun/fun.module').then(m => m.FunPageModule)
  },
  {
    path: 'fun-material',
    loadChildren: () => import('./pages/fun-material/fun-material.module').then(m => m.FunMaterialPageModule)
  },
  {
    path: 'fun-quiz1',
    loadChildren: () => import('./pages/fun-quiz1/fun-quiz1.module').then(m => m.FunQuiz1PageModule)
  },
  {
    path: 'fun-quiz2',
    loadChildren: () => import('./pages/fun-quiz2/fun-quiz2.module').then(m => m.FunQuiz2PageModule)
  },
  {
    path: 'fun-investigate',
    loadChildren: () => import('./pages/fun-investigate/fun-investigate.module').then(m => m.FunInvestigatePageModule)
  },
  {
    path: 'fun-simulation',
    loadChildren: () => import('./pages/fun-simulation/fun-simulation.module').then(m => m.FunSimulationPageModule)
  },
  {
    path: 'negotiation',
    loadChildren: () => import('./pages/negotiation/negotiation.module').then(m => m.NegotiationPageModule)
  },
  {
    path: 'sales-discipline',
    loadChildren: () => import('./pages/sales-discipline/sales-discipline.module').then(m => m.SalesDisciplinePageModule)
  },
  {
    path: 'view-job/:id',
    loadChildren: () => import('./pages/view-job/view-job.module').then(m => m.ViewJobPageModule)
  },
  {
    path: 'leadership',
    loadChildren: () => import('./pages/leadership/leadership.module').then(m => m.LeadershipPageModule)
  },
  {
    path: 'meetings',
    loadChildren: () => import('./pages/meetings/meetings.module').then(m => m.MeetingsPageModule)
  },
  {
    path: 'leadership-study-material',
    loadChildren: () => import('./pages/leadership-study-material/leadership-study-material.module').then(m => m.LeadershipStudyMaterialPageModule)
  },
  {
    path: 'negotiation-material',
    loadChildren: () => import('./pages/negotiation-material/negotiation-material.module').then(m => m.NegotiationMaterialPageModule)
  },
  {
    path: 'negotiation-quiz1',
    loadChildren: () => import('./pages/negotiation-quiz1/negotiation-quiz1.module').then(m => m.NegotiationQuiz1PageModule)
  },
  {
    path: 'negotiation-quiz2',
    loadChildren: () => import('./pages/negotiation-quiz2/negotiation-quiz2.module').then(m => m.NegotiationQuiz2PageModule)
  },
  {
    path: 'negotiation-simulation',
    loadChildren: () => import('./pages/negotiation-simulation/negotiation-simulation.module').then(m => m.NegotiationSimulationPageModule)
  },
  {
    path: 'negotiation-investigation',
    loadChildren: () => import('./pages/negotiation-investigation/negotiation-investigation.module').then(m => m.NegotiationInvestigationPageModule)
  },
  {
    path: 'meetings-material',
    loadChildren: () => import('./pages/meetings-material/meetings-material.module').then(m => m.MeetingsMaterialPageModule)
  },
  {
    path: 'meetings-quiz1',
    loadChildren: () => import('./pages/meetings-quiz1/meetings-quiz1.module').then(m => m.MeetingsQuiz1PageModule)
  },
  {
    path: 'meetings-quiz2',
    loadChildren: () => import('./pages/meetings-quiz2/meetings-quiz2.module').then(m => m.MeetingsQuiz2PageModule)
  },
  {
    path: 'meetings-simulation',
    loadChildren: () => import('./pages/meetings-simulation/meetings-simulation.module').then(m => m.MeetingsSimulationPageModule)
  },
  {
    path: 'meetings-investigation',
    loadChildren: () => import('./pages/meetings-investigation/meetings-investigation.module').then(m => m.MeetingsInvestigationPageModule)
  },
  {
    path: 'business',
    loadChildren: () => import('./pages/business/business.module').then(m => m.BusinessPageModule)
  },
  {
    path: 'business-material',
    loadChildren: () => import('./pages/business-material/business-material.module').then(m => m.BusinessMaterialPageModule)
  },
  {
    path: 'business-quiz1',
    loadChildren: () => import('./pages/business-quiz1/business-quiz1.module').then(m => m.BusinessQuiz1PageModule)
  },
  {
    path: 'business-simulation',
    loadChildren: () => import('./pages/business-simulation/business-simulation.module').then(m => m.BusinessSimulationPageModule)
  },
  {
    path: 'business-investigate',
    loadChildren: () => import('./pages/business-investigate/business-investigate.module').then(m => m.BusinessInvestigatePageModule)
  },
  {
    path: 'sales-material',
    loadChildren: () => import('./pages/sales-material/sales-material.module').then(m => m.SalesMaterialPageModule)
  },
  {
    path: 'sales-quiz1',
    loadChildren: () => import('./pages/sales-quiz1/sales-quiz1.module').then(m => m.SalesQuiz1PageModule)
  },
  {
    path: 'sales-quiz2',
    loadChildren: () => import('./pages/sales-quiz2/sales-quiz2.module').then(m => m.SalesQuiz2PageModule)
  },
  {
    path: 'sales-investigate',
    loadChildren: () => import('./pages/sales-investigate/sales-investigate.module').then(m => m.SalesInvestigatePageModule)
  },
  {
    path: 'sales-simulation',
    loadChildren: () => import('./pages/sales-simulation/sales-simulation.module').then(m => m.SalesSimulationPageModule)
  },
  {
    path: 'leadership-investigate',
    loadChildren: () => import('./pages/leadership-investigate/leadership-investigate.module').then(m => m.LeadershipInvestigatePageModule)
  },
  {
    path: 'leadership-simulate',
    loadChildren: () => import('./pages/leadership-simulate/leadership-simulate.module').then(m => m.LeadershipSimulatePageModule)
  },
  {
    path: 'leadership-quiz1',
    loadChildren: () => import('./pages/leadership-quiz1/leadership-quiz1.module').then(m => m.LeadershipQuiz1PageModule)
  },
  {
    path: 'leadership-quiz2',
    loadChildren: () => import('./pages/leadership-quiz2/leadership-quiz2.module').then(m => m.LeadershipQuiz2PageModule)
  },
  {
    path: 'education-explainer',
    loadChildren: () => import('./pages/education-explainer/education-explainer.module').then(m => m.EducationExplainerPageModule)
  },
  {
    path: 'jobs-near-me',
    loadChildren: () => import('./pages/jobs-near-me/jobs-near-me.module').then(m => m.JobsNearMePageModule)
  },
  {
    path: 'customer-investigate',
    loadChildren: () => import('./pages/customer-investigate/customer-investigate.module').then(m => m.CustomerInvestigatePageModule)
  },
  {
    path: 'email-investigate',
    loadChildren: () => import('./pages/email-investigate/email-investigate.module').then(m => m.EmailInvestigatePageModule)
  },
  {
    path: 'education-progress',
    loadChildren: () => import('./pages/education-progress/education-progress.module').then(m => m.EducationProgressPageModule)
  },
  {
    path: 'video',
    loadChildren: () => import('./pages/video/video.module').then(m => m.VideoPageModule)
  },
  {
    path: 'sponsored-learner/:id',
    loadChildren: () => import('./pages/sponsored-learner/sponsored-learner.module').then(m => m.SponsoredLearnerPageModule)
  },
  {
    path: 'contract',
    loadChildren: () => import('./pages/contract/contract.module').then(m => m.ContractPageModule)
  },
  {
    path: 'my-account',
    loadChildren: () => import('./pages/my-account/my-account.module').then(m => m.MyAccountPageModule)
  },
  {
    path: 'add-job',
    loadChildren: () => import('./pages/add-job/add-job.module').then(m => m.AddJobPageModule)
  },
  {
    path: 'complete-job/:id',
    loadChildren: () => import('./pages/complete-job/complete-job.module').then(m => m.CompleteJobPageModule)
  },
  {
    path: 'complete-job1/:id',
    loadChildren: () => import('./pages/complete-job1/complete-job1.module').then( m => m.CompleteJob1PageModule)
  },
  {
    path: 'education-campaign',
    loadChildren: () => import('./pages/education-campaign/education-campaign.module').then(m => m.EducationCampaignPageModule)
  },
  {
    path: 'budgeting-material',
    loadChildren: () => import('./pages/budgeting-material/budgeting-material.module').then(m => m.BudgetingMaterialPageModule)
  },
  {
    path: 'budgeting-quiz1',
    loadChildren: () => import('./pages/budgeting-quiz1/budgeting-quiz1.module').then(m => m.BudgetingQuiz1PageModule)
  },
  {
    path: 'cultural-material',
    loadChildren: () => import('./pages/cultural-material/cultural-material.module').then(m => m.CulturalMaterialPageModule)
  },
  {
    path: 'cultural-quiz1',
    loadChildren: () => import('./pages/cultural-quiz1/cultural-quiz1.module').then(m => m.CulturalQuiz1PageModule)
  },
  {
    path: 'media-material',
    loadChildren: () => import('./pages/media-material/media-material.module').then(m => m.MediaMaterialPageModule)
  },
  {
    path: 'media-quiz1',
    loadChildren: () => import('./pages/media-quiz1/media-quiz1.module').then(m => m.MediaQuiz1PageModule)
  },
  {
    path: 'call-material',
    loadChildren: () => import('./pages/call-material/call-material.module').then(m => m.CallMaterialPageModule)
  },
  {
    path: 'call-quiz1',
    loadChildren: () => import('./pages/call-quiz1/call-quiz1.module').then(m => m.CallQuiz1PageModule)
  },
  {
    path: 'concerns-material',
    loadChildren: () => import('./pages/concerns-material/concerns-material.module').then(m => m.ConcernsMaterialPageModule)
  },
  {
    path: 'concerns-quiz1',
    loadChildren: () => import('./pages/concerns-quiz1/concerns-quiz1.module').then(m => m.ConcernsQuiz1PageModule)
  },
  {
    path: 'write-material',
    loadChildren: () => import('./pages/write-material/write-material.module').then(m => m.WriteMaterialPageModule)
  },
  {
    path: 'write-quiz1',
    loadChildren: () => import('./pages/write-quiz1/write-quiz1.module').then(m => m.WriteQuiz1PageModule)
  },
  {
    path: 'stress-material',
    loadChildren: () => import('./pages/stress-material/stress-material.module').then(m => m.StressMaterialPageModule)
  },
  {
    path: 'stress-quiz1',
    loadChildren: () => import('./pages/stress-quiz1/stress-quiz1.module').then(m => m.StressQuiz1PageModule)
  },
  {
    path: 'questioning-material',
    loadChildren: () => import('./pages/questioning-material/questioning-material.module').then(m => m.QuestioningMaterialPageModule)
  },
  {
    path: 'questioning-quiz1',
    loadChildren: () => import('./pages/questioning-quiz1/questioning-quiz1.module').then(m => m.QuestioningQuiz1PageModule)
  },
  {
    path: 'finance-material',
    loadChildren: () => import('./pages/finance-material/finance-material.module').then(m => m.FinanceMaterialPageModule)
  },
  {
    path: 'finance-quiz1',
    loadChildren: () => import('./pages/finance-quiz1/finance-quiz1.module').then(m => m.FinanceQuiz1PageModule)
  },
  {
    path: 'closesale-material',
    loadChildren: () => import('./pages/closesale-material/closesale-material.module').then(m => m.ClosesaleMaterialPageModule)
  },
  {
    path: 'closesale-quiz1',
    loadChildren: () => import('./pages/closesale-quiz1/closesale-quiz1.module').then(m => m.ClosesaleQuiz1PageModule)
  },
  {
    path: 'ethics',
    loadChildren: () => import('./pages/ethics/ethics.module').then(m => m.EthicsPageModule)
  },
  {
    path: 'ethics-material',
    loadChildren: () => import('./pages/ethics-material/ethics-material.module').then(m => m.EthicsMaterialPageModule)
  },
  {
    path: 'ethics-quiz1',
    loadChildren: () => import('./pages//ethics-quiz1/ethics-quiz1.module').then(m => m.EthicsQuiz1PageModule)
  },
  {
    path: 'information-material',
    loadChildren: () => import('./pages/information-material/information-material.module').then(m => m.InformationMaterialPageModule)
  },
  {
    path: 'information-quiz1',
    loadChildren: () => import('./pages/information-quiz1/information-quiz1.module').then(m => m.InformationQuiz1PageModule)
  },
  {
    path: 'job-contract',
    loadChildren: () => import('./pages/job-contract/job-contract.module').then(m => m.JobContractPageModule)
  },
  {
    path: 'audiences-material',
    loadChildren: () => import('./pages/audiences-material/audiences-material.module').then(m => m.AudiencesMaterialPageModule)
  },
  {
    path: 'audiences-quiz1',
    loadChildren: () => import('./pages/audiences-quiz1/audiences-quiz1.module').then(m => m.AudiencesQuiz1PageModule)
  },
  {
    path: 'communication-material',
    loadChildren: () => import('./pages/communication-material/communication-material.module').then(m => m.CommunicationMaterialPageModule)
  },
  {
    path: 'communication-quiz1',
    loadChildren: () => import('./pages/communication-quiz1/communication-quiz1.module').then(m => m.CommunicationQuiz1PageModule)
  },
  {
    path: 'team-material',
    loadChildren: () => import('./pages/team-material/team-material.module').then(m => m.TeamMaterialPageModule)
  },
  {
    path: 'team-quiz1',
    loadChildren: () => import('./pages/team-quiz1/team-quiz1.module').then(m => m.TeamQuiz1PageModule)
  },
  {
    path: 'emotional-material',
    loadChildren: () => import('./pages/emotional-material/emotional-material.module').then(m => m.EmotionalMaterialPageModule)
  },
  {
    path: 'emotional-quiz1',
    loadChildren: () => import('./pages/emotional-quiz1/emotional-quiz1.module').then(m => m.EmotionalQuiz1PageModule)
  },
  {
    path: 'effectiveness-material',
    loadChildren: () => import('./pages/effectiveness-material/effectiveness-material.module').then(m => m.EffectivenessMaterialPageModule)
  },
  {
    path: 'effectiveness-quiz1',
    loadChildren: () => import('./pages/effectiveness-quiz1/effectiveness-quiz1.module').then(m => m.EffectivenessQuiz1PageModule)
  },
  {
    path: 'geography-material',
    loadChildren: () => import('./pages/geography-material/geography-material.module').then(m => m.GeographyMaterialPageModule)
  },
  {
    path: 'geography-quiz1',
    loadChildren: () => import('./pages/geography-quiz1/geography-quiz1.module').then(m => m.GeographyQuiz1PageModule)
  },
  {
    path: 'legal-material',
    loadChildren: () => import('./pages/legal-material/legal-material.module').then(m => m.LegalMaterialPageModule)
  },
  {
    path: 'legal-quiz1',
    loadChildren: () => import('./pages/legal-quiz1/legal-quiz1.module').then(m => m.LegalQuiz1PageModule)
  },

  {
    path: 'audiences',
    loadChildren: () => import('./pages/audiences/audiences.module').then(m => m.AudiencesPageModule)
  },
  {
    path: 'budgeting',
    loadChildren: () => import('./pages/budgeting/budgeting.module').then(m => m.BudgetingPageModule)
  },
  {
    path: 'call',
    loadChildren: () => import('./pages/call/call.module').then(m => m.CallPageModule)
  },
  {
    path: 'closesale',
    loadChildren: () => import('./pages/closesale/closesale.module').then(m => m.ClosesalePageModule)
  },
  {
    path: 'communication',
    loadChildren: () => import('./pages/communication/communication.module').then(m => m.CommunicationPageModule)
  },
  {
    path: 'concerns',
    loadChildren: () => import('./pages/concerns/concerns.module').then(m => m.ConcernsPageModule)
  },
  {
    path: 'cultural',
    loadChildren: () => import('./pages/cultural/cultural.module').then(m => m.CulturalPageModule)
  },
  {
    path: 'effectiveness',
    loadChildren: () => import('./pages/effectiveness/effectiveness.module').then(m => m.EffectivenessPageModule)
  },
  {
    path: 'emotional',
    loadChildren: () => import('./pages/emotional/emotional.module').then(m => m.EmotionalPageModule)
  },
  {
    path: 'finance',
    loadChildren: () => import('./pages/finance/finance.module').then(m => m.FinancePageModule)
  },
  {
    path: 'geography',
    loadChildren: () => import('./pages/geography/geography.module').then(m => m.GeographyPageModule)
  },
  {
    path: 'information',
    loadChildren: () => import('./pages/information/information.module').then(m => m.InformationPageModule)
  },
  {
    path: 'media',
    loadChildren: () => import('./pages/media/media.module').then(m => m.MediaPageModule)
  },
  {
    path: 'questioning',
    loadChildren: () => import('./pages/questioning/questioning.module').then(m => m.QuestioningPageModule)
  },
  {
    path: 'stress',
    loadChildren: () => import('./pages/stress/stress.module').then(m => m.StressPageModule)
  },
  {
    path: 'team',
    loadChildren: () => import('./pages/team/team.module').then(m => m.TeamPageModule)
  },
  {
    path: 'write',
    loadChildren: () => import('./pages/write/write.module').then( m => m.WritePageModule)
  },
  {
    path: 'edit-job',
    loadChildren: () => import('./pages/edit-job/edit-job.module').then( m => m.EditJobPageModule)
  },
  {
    path: 'wifi-operator/:id',
    loadChildren: () => import('./pages/wifi-operator/wifi-operator.module').then( m => m.WifiOperatorPageModule)
  },
  {
    path: 'wifi-master/:id',
    loadChildren: () => import('./pages/wifi-master/wifi-master.module').then( m => m.WifiOperatorPageModule)
  },
  {
    path: 'wifi-manager/:id',
    loadChildren: () => import('./pages/wifi-manager/wifi-manager.module').then( m => m.WifiManagerPageModule)
  },
  {
    path: 'voucher-history/:id',
    loadChildren: () => import('./pages/voucher-history/voucher-history.module').then( m => m.VoucherHistoryPageModule)
  },
  {
    path: 'voucher-stats/:id',
    loadChildren: () => import('./pages/voucher-stats/voucher-stats.module').then( m => m.VoucherStatsPageModule)
  },
  {
    path: 'sales-stats/:id',
    loadChildren: () => import('./pages/sales-stats/sales-stats.module').then( m => m.SalesStatsPageModule)
  },
  {
    path: 'payment-complete',
    loadChildren: () => import('./pages/payment-complete/payment-complete-routing.module').then( m => m.PaymentCompletePageRoutingModule)
  },
  {
    path: 'payment-cancelled',
    loadChildren: () => import('./pages/payment-cancelled/payment-cancelled.module').then( m => m.PaymentCancelledPageModule)
  },
  {
    path: 'netcare-vouchers',
    loadChildren: () => import('./pages/netcare-vouchers/netcare-vouchers.module').then( m => m.NetcareVouchersPageModule)
  },
  {
    path: 'netcare-providers',
    loadChildren: () => import('./pages/netcare-providers/netcare-providers.module').then( m => m.NetcareProvidersPageModule)
  },
  {
    path: 'netcare-cancel',
    loadChildren: () => import('./pages/netcare-cancel/netcare-cancel.module').then( m => m.NetcareCancelPageModule)
  },
  {
    path: 'hustler-stats',
    loadChildren: () => import('./pages/hustler-stats/hustler-stats.module').then( m => m.HustlerStatsPageModule)
  },
  {
    path: 'device-details',
    loadChildren: () => import('./pages/sub-components/device-details/device-details.module').then( m => m.DeviceDetailsPageModule)
  },
  {
    path: 'wifi-master-operator/:id',
    loadChildren: () => import('./pages/wifi-master-operator/wifi-master-operator.module').then( m => m.WifiMasterOperatorPageModule)
  },

  { path: '**', redirectTo: 'home', pathMatch: 'full' },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
