import { Component, Input } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-hustler-details',
  templateUrl: './hustler-details.page.html',
  styleUrls: ['./hustler-details.page.scss'],
})
export class HusterDetails {

  vouchers = [];
  id;
  values = [];
  keys = [];
  @Input() device; any;
  constructor(
    private afs: AngularFirestore,
    private modalController: ModalController
    ) {
  }

  ionViewDidEnter() {
    console.log('this is the device');
    console.log(this.device);
    this.values = Object.values(this.device);
    this.keys = Object.keys(this.device);
    this.values[0] = [];
    this.values[1] = []
    console.log(this.values);
    
  }
  getType(object){
    return typeof object
   }
  close(){
    this.modalController.dismiss();
  }
}
