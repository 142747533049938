import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-paymenttutorial',
  templateUrl: './paymenttutorial.component.html',
  styleUrls: ['./paymenttutorial.component.scss'],
})
export class PaymenttutorialComponent implements OnInit {

  option = true;
  pf = false;
  v1 = false;

  constructor() { }

  ngOnInit() { }


  payTut() {
    this.option = false;
    this.pf = true;

  }
  oneVtut() {
    this.option = false;
    this.v1 = true;
  }



}
