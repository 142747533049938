import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { PaymenttutorialComponent} from './paymenttutorial/paymenttutorial.component';
import {HusterDetails} from './hustler-details/hustler-details.page';
import {DeviceSettings} from './device-settings/device-settings.page';
import {PaymentComponent} from './payment/payment.component';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule
  ],
  declarations: [ HusterDetails, DeviceSettings, PaymenttutorialComponent, PaymentComponent],
  exports: [ HusterDetails, DeviceSettings, PaymenttutorialComponent, PaymentComponent],
  entryComponents: [ HusterDetails, DeviceSettings, PaymenttutorialComponent, PaymentComponent]
})
export class SubComponentsModule {}
