import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class PayNotifyService {
    constructor(
        private afs: AngularFirestore,
        private router: Router
    )   {

    }
    public async payNotify(key) {
        const observe =  new Observable<any>(s =>    {
            this.afs.collection('operators').doc(key).valueChanges().subscribe((operator: any) =>    {
                s.next(operator.lastPaid);
            })
        })
        return observe;
    }
    public async payNotifyInit(key) {
        const observe =  new Observable<any>(s =>    {
            this.afs.collection('operators').doc(key).valueChanges().subscribe((operator: any) =>    {
                s.next(operator);
            })
        })
        return observe;
    }
    public async backHome()   {
        setTimeout(() => {
            this.router.navigate(['home']);
            console.log('triggererererererererererererererre');
            
        }, 10000);
    }
}