import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-policy',
  templateUrl: './policy.page.html',
  styleUrls: ['./policy.page.scss'],
})
export class PolicyPage implements OnInit {

  params;
  string;

  constructor(public navParams: NavParams, public modalCtrl: ModalController) {
    this.params = navParams.data;
  }

  ngOnInit() {
    this.string = this.params.string
    console.log(this.string)
  }

  exit() {
    this.modalCtrl.dismiss();
  }

}
