import { Injectable } from "@angular/core";
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { Platform } from "@ionic/angular";
import { PopupHelper } from "./popup-helper";

@Injectable()
export class ClipBoardService {
    constructor(
        private clipBoard: Clipboard,
        private platform: Platform,
        private popUp: PopupHelper
    )   {

    }
    copyText(text: string)  {
        this.platform.ready().then(() =>    {
            if(this.platform.is("cordova")) {
                this.clipBoard.copy(text).then(() =>    {
                    this.popUp.showToast('Coppied to clipboard')
                }).catch((error: any) =>   {
                    this.popUp.showError(error)
                })
            }
            else    {
                navigator.clipboard.writeText(text).then(() =>  {
                    this.popUp.showToast('Coppied to clipboard')
                }).catch((e: any) => {
                    this.popUp.showError(e);
                });
            }
        });
    }
}