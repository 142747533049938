import { LoadingService } from '../../../services/loading.service';
import { HttpClient } from '@angular/common/http';
import { Component, ViewChild, ElementRef, Input } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { SalesService } from '../../voucher-stats/sales.service';
import { Storage } from '@ionic/storage';
import md5 from 'md5';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

import { NgForm } from '@angular/forms';
import { PayNotifyService } from '../../../services/pay-notify/pay-notify.service';
import { AlertController, ModalController, Platform } from '@ionic/angular';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { ToastService } from 'src/app/services/toast.service';
import { PopupHelper } from '../../../services/helpers/popup-helper';
import moment from 'moment'
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent {
  @ViewChild('iFrame', { static: true }) iframe: ElementRef;

  id;
  oneVoucher = {
    token: '',
    sequenceNumber: 0,
    amountRequested: 0,
    voucherPin: ''
  }
  voucher = false;
  start = true;
  payfast = false
  payFastSignature: string;
  user: any = {};
  operator: any = {};
  notify_url: string = "https://us-central1-mahala-app.cloudfunctions.net/initPaymentPayfast";
  return_url: string = "https://pasella-app.web.app/#/payment-complete" //"http://localhost:8100/menu/success"
  cancel_url: string = "https://pasella-app.web.app/#/payment-cancelled";
  @ViewChild('testForm') testFormElement: NgForm;
  price: string;
  submitted = false;
  height = "50"
  subsciption: Subscription;
  @Input() box: any;
  initPrice: string;
  constructor(
    private http: HttpClient,
    private loading: LoadingService,
    public activatedRoute: ActivatedRoute,
    private afs: AngularFirestore,
    private salesService: SalesService,
    private storage: Storage,
    private iab: InAppBrowser,
    private router: Router,
    public alertCtrl: AlertController,
    private platform: Platform,
    private fcm: FirebaseX,
    private toast: ToastService,
    private payNotify: PayNotifyService,
    private popUp: PopupHelper,
    private modalCtrl: ModalController

  ) {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.subsciption = new Subscription();
  }
  getPrice() {
    const promise = new Promise((resolve, reject) => {
      this.afs.collection('fees').doc('price').ref.get().then((price) => {
        this.price = price.data().price;
        this.afs.collection('fees').doc('initialPrice').ref.get().then((initPrice: any) =>  {
          this.initPrice = initPrice.data().price;
          resolve('');

        })
      });
    });
    return promise;
  }
  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.

    this.getPrice().then(() =>  {
    });
  }
  ionViewWillEnter() {
    this.storage.get('user').then((user: any) => {
      this.user = user;
      console.log('this is the user');
      console.log(user);
      this.subToBox(this.box.key);
    });
  }

  async subToBox(key) {
    this.subsciption = await (await this.payNotify.payNotifyInit(key)).subscribe((operator: any) => {
      console.log('operator in subscription');
      console.log(operator);

      if (operator.initialPayment) {
        this.popUp.showAlert('Payment Successful', 'Your account has been activated for another week. Thank you').then(() => {
          // this.router.navigate(['wifi-operator/' + this.box.key])
          this.modalCtrl.dismiss('complete');
        })
      }
    })
  }


  getToken() {
    const promise = new Promise((resolve, reject) => {
      this.fcm.getToken().then((token) => {
        console.log('this is the token');
        console.log(token);
        resolve(token)
      })
    })
    return promise;
  }
  async operatorAlert() {
    let prompt = await this.alertCtrl.create({
      header: 'INVALID DETAILS',
      cssClass: 'alert',
      message: `The Operator Login Details provided are incorrect. Please try again or speak to your administrator`,
      buttons: [
        {
          text: 'OKAY',
          role: 'cancel'
        }
      ]
    });
    return await prompt.present();
  }
  ionViewDidEnter() {
  }

  buy() {
    try {
      this.executePayFast();
    } catch (error) {
      console.log(error);

    }
    //console.log(amount)
    //this.afs.collection('users').doc(this.userKey).update({ credits: bundle.credit })
    //console.log(`${bundle.credit} credits added to ${this.userKey}`)
  }

  async executePayFast() {

    let signatureBuilder = await
      `merchant_id=10000100
    &merchant_key=46f0cd694581a
    &amount=${this.initPrice}
    &item_name=${'pasella'}
    &notify_url=https%3A%2F%2Fus-central1-mahala-app.cloudfunctions.net%2Fpayfast
    &cancel_url=https%3A%2F%2Fpasella-app.web.app%2F%23%2Fpayment-cancelled
    &return_url=https%3A%2F%2Fpasella-app.web.app%2F%23%2Fpayment-complete
    &m_payment_id=${this.box.key}
    &name_first=${'algor'}
    &name_last=${'lector'}
    &email_address=${'algorsnipes@gmail.com'}
    &email_confirmation=1
    &confirmation_address=${'algorsnipes@gmail.com'}`;

    signatureBuilder = await signatureBuilder.trim();
    signatureBuilder = await signatureBuilder.replace(/\s/g, '+');


    this.payFastSignature = await md5(signatureBuilder);

  }
 
  back() {
    this.modalCtrl.dismiss();
  }
  onSubmit() {
    console.log('form sumbitted..............................................');
    this.submitted = true;
    setTimeout(() => {
      // let uri = this.iframe.nativeElement;
    }, 10000);
  }
  onLoadFunc() {

  }
  ngOnDestroy() {
    this.subsciption.unsubscribe();

  }

  oneV() {
    this.start = false;
    this.voucher = true;
    this.http.get('https://us-central1-mahala-app.cloudfunctions.net/oneVoucherToken')
      .subscribe((data: any) => {
        this.oneVoucher.token = data.access_token
        this.oneVoucher.sequenceNumber = parseInt(moment().format('YYYYMMDD') + this.initPrice)
        this.oneVoucher.amountRequested = parseInt(this.initPrice)
        console.log('coll', this.oneVoucher);

      });
  }

  pay() {
    this.start = false;
    this.payfast = true;
  }

  redeem1Voucher() {
    this.loading.present("Redeeming...")
    // 'https://us-central1-mahala-app.cloudfunctions.net/oneVoucher'
    this.http.post('https://us-central1-mahala-app.cloudfunctions.net/oneVoucher', JSON.stringify(this.oneVoucher))
      .subscribe((data: any) => {
        this.loading.dismiss().then(() => {
          if (data.actionCode == '0000') {
            //succsess
            this.afs.collection('operators').doc(this.box.key).update(
              {
                'initialPayment': true,
                'status': 'enabled'
              }).then(() => {
                this.popUp.showAlert('Payment Successful', 'Your account has been activated for another week. Thank you').then(() => {
                  this.modalCtrl.dismiss('complete')
                })
              })
          }
          else {
            this.redeemAlert(data.screenMessage)
          }
        })
      });
  }

  async redeemAlert(msg) {
    let alert = await this.alertCtrl.create({
      header: '1Voucher',
      message: `${msg}`,
      buttons: [
        {
          text: 'OK'

        }
      ]
    });
    return await alert.present();
  }

}
